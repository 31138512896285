import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../components/layout.css"
import { useLocation } from "@reach/router"
import Seo from "../components/SEOComponents/Seo"
import Footer from "../components/FooterComponents/Footer"
import FeatureSection from "../components/FeatureSection/FeatureSection"
import VideoMainSection from "../components/VideoMainSection/VideoMainSection"
import Testimonies from "../components/HomeComponents/Testimonies"
import Partner from "../components/HomeComponents/Partner"
import BlogContainer from "../components/HomeComponents/BlogContainer"
import Media from "../components/SendMoney/Media"
import Investor from "../components/HomeComponents/Investor"
import MissionSection from "../components/Mission Section/MissionSection"
import CountryList from "../components/HomeComponents/CountryList"
import { HowItWorks } from "../components/HomeComponents/HowItWorks"
import { Header } from "../components/HomeComponents/Header"
import { HowToUse } from "../components/HomeComponents/HowToUse"

import { MainPageJson, PathJson } from "../components/location"
import { NavigationBar } from "../components/NavigationBar/parent"
import Download from "../components/HomeComponents/Download"

function IndexPage() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  return (
    <div className="maxwidth">
      <Seo title="homepage" />
      <NavigationBar showToggleBusiness showBanner />
      <Header
        props={MainPageContent.Header}
        propsLink={PathContent}
        isEnglish={isEnglish}
      />
      <VideoMainSection props={MainPageContent.Video} isEnglish={isEnglish} />
      <FeatureSection props={MainPageContent.Advantages} />
      <HowItWorks props={MainPageContent.HowItWorks} isEnglish={isEnglish} />
      <HowToUse props={MainPageContent.HowToUse} />
      <CountryList props={MainPageContent.Countries} />
      <Testimonies />
      <BlogContainer isEnglish={isEnglish} />
      <Download props={MainPageContent.Download} />
      {/* <Partner isEnglish={isEnglish} /> */}
      {/* <Media mainPage={true} />
      <Investor isEnglish={isEnglish} />
      <MissionSection
        props={MainPageContent.Mission}
        propsLink={PathContent}
        isEnglish={isEnglish}
      /> */}
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default IndexPage
