import React from "react"
import { LiteYoutubeEmbed } from "react-lite-yt-embed"
import { Col, Container, Row } from "react-bootstrap"
import { SendMoneyPath } from "../location"
import { IdComponent } from "../Reusable/id-components"
import ReactPlayer from "react-player/lazy"
import styled from "styled-components"
import videoGlobe from "../../images/video-globe.svg"
import bgGreyRotate from "../../images/logo-big-grey.svg"
import { BackgroundImg } from "../HomeComponents/HowToUse"

function VideoMainSection({ props, country, propsData, isEnglish, isSg }) {
  const sendMoneyPath = SendMoneyPath()
  const youtubeId = isSg ? "QoaTEmSgx1Y" : isEnglish ? "1_sKcXIlpHU" : "qOYBzjkKzDg"
  const textVideo = isEnglish
    ? "Discover companion for your purposeful cross-border financial transaction"
    : "Pelajari solusi dari kami untuk ciptakan transaksi penuh makna"
  return (
    <>
      <IdComponent title="video" />
      <Container>
        <CustomDiv
          pbottom={sendMoneyPath && "40px"}
          ptop={sendMoneyPath ? "100px" : '0px'}
          mtop={!sendMoneyPath && "200px"}
        >
          <VideoContainer>
            <Row>
              <Col lg={6}>
                <TitleH2>
                  {sendMoneyPath ? (
                    <>
                      {propsData.Title}
                      {isEnglish ? country.country_en : country.country_id}
                      {propsData.Title1}
                    </>
                  ) : (
                    <>{textVideo}</>
                  )}
                </TitleH2>
              </Col>
              <VideoCol lg={6}>
                <ImgGlobe src={videoGlobe} alt="globe" />
                <VideoDiv>
                  <LiteYoutubeEmbed id={youtubeId} />
                </VideoDiv>
              </VideoCol>
            </Row>
          </VideoContainer>
          {sendMoneyPath && (
            <>
              <BackgroundImg
                src={bgGreyRotate}
                alt="logo"
                top="-500px"
                left="-700px"
                rotate="180deg"
              />
              <BackgroundImg
                src={bgGreyRotate}
                alt="logo"
                width="500px"
                top="400px"
                left="900px"
              />
            </>
          )}
        </CustomDiv>
      </Container>
    </>
  )
}

export default VideoMainSection

const VideoDiv = styled.div`
  width: 425px;
  height: 250px;
  @media (max-width: 576px) {
    width: 100%;
    height: 150px;
  }
`

const CustomReactPlayer = styled(ReactPlayer)`
  height: 250px !important;
  @media (max-width: 576px) {
    height: 150px !important;
  }
`

const CustomDiv = styled.div`
  padding-top: ${p => p.ptop || "150px"};
  padding-bottom: ${p => p.pbottom || "100px"};
  margin-top: ${p => p.mtop};
  position: relative;
  @media (max-width: 576px) {
    padding-top: 50px;
    margin-top: ${p => p.mtopres};
    padding-bottom: 50px;
  }
`

const VideoContainer = styled(Container)`
  overflow: hidden;
  border-radius: 15px;
  box-shadow: -5px 4px 25px rgba(73, 171, 184, 0.2);
  padding: 47px 42px;
  background-color: white;
  @media (max-width: 576px) {
    width: 95%;
    padding: 47px 22px;
  }
`

const TitleH2 = styled.h2`
  color: #102c57;
  font-family: "Typefez-black";
  font-size: 46px;
  @media (max-width: 968px) {
    text-align: center;
    margin-bottom: 3rem;
  }
  @media (max-width: 768px) {
    font-size: 32px;
  }
`
const VideoCol = styled(Col)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImgGlobe = styled.img`
  position: absolute;
  z-index: 0;
  top: -80px;
  right: -110px;
  @media (max-width: 768px) {
    display: none;
  }
`
