import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import { CustomAccordion } from "./Accordion"
import { CustomDot } from "../Reusable/dot-line"
import globeImg from "../../images/globe-country-list.webp"
import { IdComponent } from "../Reusable/id-components"

function CountryList({ props }) {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  const data = useStaticQuery(graphql`
    {
      list: allStrapiListOfCountries {
        nodes {
          id
          Active
          Available
          slug
          country_en
          country_id
          continent_en
          continent_id
          estimate_en
          estimate_id
          price
          limit
          iso
          iso_3
        }
      }
    }
  `)

  const sortedListId = [...data.list.nodes].sort((a, b) =>
    a.country_id > b.country_id ? 1 : b.country_id > a.country_id ? -1 : 0
  )
  const sortedListEn = [...data.list.nodes].sort((a, b) =>
    a.country_en > b.country_en ? 1 : b.country_en > a.country_en ? -1 : 0
  )
  const dataList = isEnglish ? sortedListEn : sortedListId
  // const globeImg = data.globe.childImageSharp.fluid

  const {
    Title,
    Desc,
    TitleAsia,
    TitleAmerica,
    TitleAfrica,
    TitleEurope,
  } = props

  const ContinentData = [
    {
      eventId: "0",
      idName: "Asia",
      name: TitleAsia,
    },
    {
      eventId: "1",
      idName: "America",
      name: TitleAmerica,
    },
    {
      eventId: "2",
      idName: "Africa",
      name: TitleAfrica,
    },
    {
      eventId: "3",
      idName: "Europe",
      name: TitleEurope,
    },
  ]

  return (
    <>
      <IdComponent title="countries" />
      <CustomDiv>
        <div className="text-center">
          <CustomTitle>{Title}</CustomTitle>
          <CustomDesc>{Desc}</CustomDesc>
        </div>
        <CustomContainer>
          <CustomImg src={globeImg} alt="globe" />
          <CustomAccordion
            data={ContinentData}
            countryList={dataList}
            textCountries={props}
            isCountry
          />
          <DivDescription>
            <CustomDisclaimer>{props.Desc3}</CustomDisclaimer>
            <div>
              <CustomActiveTerm contentColor="white">
                {props.Desc1}
              </CustomActiveTerm>
              <CustomActiveTerm>{props.Desc2}</CustomActiveTerm>
            </div>
          </DivDescription>
        </CustomContainer>
      </CustomDiv>
    </>
  )
}

export default CountryList

const CustomDiv = styled.div`
  margin: 100px 10px 200px 10px;
  @media (max-width: 576px) {
    margin: 50px 10px 100px 10px;
  }
  /* box-shadow: -3px 14px 20px 12px rgba(0, 0, 0, 0.03) inset;
  -webkit-box-shadow: -3px 14px 20px 12px rgba(0, 0, 0, 0.03) inset;
  -moz-box-shadow: -3px 14px 20px 12px rgba(0, 0, 0, 0.03) inset; */
`

const CustomImg = styled.img`
  position: absolute !important;
  top: -90px;
  right: -15%;
  z-index: 1;
  width: 450px;
  @media (max-width: 992px) {
    display: none;
  }
`

const CustomContainer = styled(Container)`
  margin-top: 70px;
  position: relative;
  padding: 0px 50px 30px 50px;
  background-color: #0b7c8c;
  border-radius: 18px;
  @media (max-width: 576px) {
    padding: 0px 10px 20px 30px;
  }
`

const CustomTitle = styled.h2`
  color: #102c57;
  font-family: "Typefez-extrabold";
  font-size: 50px;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`

const CustomDesc = styled.h3`
  font-size: 22px;
  color: #102c57;
  font-family: "Typefez-med";
  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const CustomDisclaimer = styled.h5`
  font-size: ${p => p.fsize || "14px"};
  color: white;
  font-family: "Typefez-reg";
`

const CustomActiveTerm = styled.p`
  color: ${p => p.color || "white"};
  font-size: ${p => p.fs || "14px"};
  text-align: ${p => p.align || "left"};
  width: ${p => p.width || "100%"};
  font-family: "GothamBook";
  margin: 0px 0px;
  ::before {
    margin-right: 5px;
    font-family: FontAwesome;
    content: "\f111";
    color: ${p => p.contentColor || "#A6A6AC"};
  }
  @media (max-width: 576px) {
    font-size: 10px;
    width: auto;
  }
`

const DivDescription = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`
