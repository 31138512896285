import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./banner.css"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"

export const Banner = ({ isSg }) => {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const data = useStaticQuery(graphql`
    {
      bannerId: allStrapiBannerWebIds {
        nodes {
          id
          link: bannerIdLink
          image: bannerIdImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          linkEn: bannerIdLinkEnglish
          imageEn: bannerIdImageEnglish {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      bannerSg: allStrapiBannerWebSgs {
        nodes {
          id
          link: bannerSgLink  
          image: bannerSgImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          linkEn: bannerSgLinkEnglish
          imageEn: bannerSgImageEnglish {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const bannerData = isSg ? data?.bannerSg?.nodes : data?.bannerId?.nodes
  var InitSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 200,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <Slider {...InitSettings}>
        {bannerData?.map(item => {
          const bannerLink = isEnglish ? item.linkEn : item.link
          const bannerImg = isEnglish
            ? item?.imageEn?.childImageSharp?.fluid?.src
            : item?.image?.childImageSharp?.fluid?.src
          return (
            <div key={item.id}>
              <a href={bannerLink} target="_blank">
                <CustomImg
                  loading="lazy"
                  src={bannerImg}
                  alt="banner"
                  height={isSg && "350px"}
                  heightres={!isSg && "300px"}
                />
              </a>
            </div>
          )
        })}
      </Slider>
    </>
  )
}

const CustomImg = styled.img`
  width: 700px;
  height: auto;
  @media (max-width: 576px) {
    width: 100%;
    height: auto;
  }
`
